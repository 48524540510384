import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Logo from '../components/logo'
import Info from '../components/info'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gradientIsOpen: false,
    }
  }

  componentDidMount() {
    let timeout = false,
      delay = 250

    function calculateDeviceVh() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    window.addEventListener('resize', function() {
      clearTimeout(timeout)
      timeout = setTimeout(calculateDeviceVh, delay)
    })

    calculateDeviceVh()
  }

  toggleGradient = () => {
    this.setState({ gradientIsOpen: !this.state.gradientIsOpen })
  }

  render() {
    return (
      <Layout>
        <SEO title="Claire Jones" location={this.props.location} />

        <div
          className={`page relative theme ${
            this.state.gradientIsOpen ? 'theme--white overflow-hidden' : ''
          }`}
        >
          <div className="absolute pin-t pin-l mt-6 ml-6 lg:mt-14 lg:ml-14 delayed-fade-in">
            <div
              className={`gradient ${
                this.state.gradientIsOpen ? 'gradient--full' : ''
              }`}
            >
              <div
                className={`text-white mt-6 ml-6 lg:mt-14 lg:ml-14 ${
                  this.state.gradientIsOpen ? 'fade-up' : 'hidden'
                }`}
              >
                <h2 className="text-sm md:text-2xl xl:text-4xl font-medium">
                  Let's work together
                </h2>
                <p className={`text-xs xs:text-sm md:text-2xl xl:text-4xl w-7/10 md:w-3/4`}>
                  Hi, I'm Claire — a marketing and comms consultant with a
                  passion for helping organisations connect, engage and inspire.
                  I specialise in strategy, campaign development, copy-writing
                  and the 'nitty gritty' of project delivery. Over the past 15
                  years I've worked client and agency-side, and I'm now able to
                  bring in the best people for the job, drawing on my trusted
                  network of creative specialists. If you have a project coming
                  up or are just doing a bit of dreaming and scheming, I'd love
                  to chat!
                </p>
              </div>
            </div>
          </div>

          <div className="absolute pin-t pin-r mt-6 mr-6 lg:mt-14 lg:mr-14 delayed-fade-in">
            <button
              onClick={this.toggleGradient}
              className="text-sm lg:text-base font-medium focus:outline-none text-inherit"
            >
              {this.state.gradientIsOpen ? 'Close' : 'Info'}
            </button>
          </div>

          <div className="absolute pin-b pin-l mb-6 ml-6 lg:mb-14 lg:ml-14 delayed-fade-in">
            <Info />
          </div>

          <div className="absolute pin-b pin-r mb-6 mr-5 lg:mb-14 lg:mr-12">
            <Logo />
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
