import React from 'react'
import PropTypes from 'prop-types'

import '../styles/index.css'

const Layout = ({ children }) => (
  <>
    <div className="font-sans leading-tight text-black antialiased">
      {children}
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
