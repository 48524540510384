import React from 'react'

class Info extends React.Component {
  render() {
    return (
      <div className="lg:flex">
        <div>
          <h2 className="text-base font-medium">Claire Jones</h2>
          <h1 className="text-base font-medium">Daybreak Consulting</h1>
        </div>
        <ul className="list-reset lg:ml-24 xl:ml-32">
          <li>
            <span className="hidden lg:inline-block lg:w-8">P:</span>
            <a
              className="text-inherit no-underline hover:underline focus:underline"
              href="tel:+64212084104"
            >
              +64 21 208 4104
            </a>
          </li>
          <li>
            <span className="hidden lg:inline-block lg:w-8">E:</span>
            <a
              className="text-inherit no-underline hover:underline focus:underline"
              href="mailto:claire@daybreak.nz"
            >
              claire@daybreak.nz
            </a>
          </li>
        </ul>
        <ul className="list-reset lg:ml-24 xl:ml-32 hidden lg:block">
          <li>Christchurch</li>
          <li>New Zealand</li>
        </ul>
      </div>
    )
  }
}

export default Info
